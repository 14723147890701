/* Header.js----------------------------------------------------------------------------*/
.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #f4f4f4;
    text-decoration: none;
    border-bottom: solid 3px;
}

/* 로고 및 소프트웨어학과 타이틀 */
.header-link {
    display: flex;
    align-items: center;
    flex-direction: row;
}
.header-link:hover {
    text-decoration: none;
}
.logo {
    width: 120px;
    margin: 0 15px 0 0;
    box-shadow: 0 0 0px;
}
h1 {
    font-size: 20px;
    margin-top: 10px;
    color: 333;
}

/*로그인, 사이트맵 메뉴바*/
.top-bar {
    display: flex;
    align-items: center;
    margin-top: -10px;
}
.top-bar p {
    font-size: 15px;
    color: #333;
    margin-top: -2px;
}

.top-link {
    font-size: 15px;
    margin-left: 10px;
    text-decoration: none;
    color: #333;
}
.top-link:hover {
    color: #FCAF17;
    text-decoration: none;
}

/* 왼쪽 사이드 메뉴바*/
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100px;
    background-color: #1E3252;
    overflow-x: hidden;
    transition: width 0.5s;
    padding-top: 20px;
    text-align: center;
    z-index: 4;     /* 사이드바의 쌓임 순서를 조절하는 값 */
}
.sidebar:hover {
    width: 150px; /* 호버 시 확장 너비 */
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar ul li {
    padding: 70px 0px 0px 0px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    color: #fff;
}

.hoverable-link:hover {
    color: #FCAF17;
    text-decoration: none;
}

.sidebar ul li::before {
    content: "o";
    padding: 70px 0px 0px 35px;
    font-size: 8px;
    color: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;
    transition: opacity 0.3s;
}

.sidebar ul li a {
    font-weight: normal;
    color: #fff;
    text-decoration: none;
    display: block;
    transition: background-color 0.3s, padding-left 0.3s;
    position: relative;
    padding-left: 30px;
    opacity: 0; /* 초기에는 투명하게 설정 */
    transition: opacity 0.3s;
}

.sidebar:hover ul li::before {
    opacity: 0;
}
.sidebar:hover ul li a {
    opacity: 1;
}

/* 추가 사이드 메뉴바 */
.sidebar_plus {
    position: fixed;
    top: 0;
    left: 100px;
    width: 0; /* 초기에는 사이드바를 숨김 */
    height: 100%;
    background-color: #1E3252;
    opacity: 0.95;
    overflow-x: hidden;
    transition: width 0.5s;
    z-index: 3;
}

.sidebar_plus ul {
    list-style-type: none;
    padding: 50px 0 0 0;
}

.sidebar_plus ul li {
    padding: 30px 0px 0px 80px;
    text-align: left;
    font-weight: lighter;
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative; /* 상대적 위치 설정 */
}

/* Footer.js----------------------------------------------------------------------------*/
.footer {
    background-color: #333;
    margin-top: 50px;
    margin-left: -50px;
    padding: 10px 50px 20px 50px;
    text-align: center;
    position: relative;
    bottom: 0;
    width: 100%;
    z-index: 0;
}

.additional-link{
    margin-top: 30px;
    margin-bottom: 10px;
    text-align: center;
    color: #fff;
}
.additional-link > * {
    margin: 0 5px; /* '|' 기호 좌우에 여백 추가 */
}

.footer p {
    margin: 0px 0;
    font-weight: lighter;
    font-size: 15px;
    color: #ccc;
}

@media (max-width: 630px) {
    .top-bar {
        display: none; /* 로그인 및 사이트맵 숨기기*/
    }
    h1{
        font-size: 18px;
    }
    .additional-link > * {
        margin: 0 3px;
    }    
}