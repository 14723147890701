@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap');

body {
  font-family: 'Noto Sans KR', sans-serif;
  margin-left: 150px;
  margin-right: 50px;
  margin-top: 20px;
  padding: 0px;
  background-color: #f4f4f4;
  color: #333;
  caret-color: transparent; /* 텍스트 입력 커서 숨기기 */
}

/* 각 화면 타이틀---------------------------------------------*/
.title {
    margin-top: 50px;
    margin-bottom: 28px;
    color: #333;
    font-weight: bolder;
    font-size: 30px;
    text-align: center;
    position: relative;
}
.title::after {
    content: "";
    display: block;
    width: 50px; /* 밑줄의 너비 조정 */
    height: 3px; /* 밑줄의 높이 조정 */
    background-color: #333; /* 밑줄의 색상 조정 */
    margin: 0 auto;
    margin-top: 10px; /* 텍스트와 밑줄 사이의 간격 조정 */
}

/* 로그인 및 회원가입 화면---------------------------------------------*/
.login-container {
    max-width: 400px;
    margin: 30px auto;
    margin-bottom: 10px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center; /* 추가: 자식 요소 중앙 정렬 */
}

.login-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.input-group {
    display: flex;
    flex-direction: column;
}

.input-group input {
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.login-button {
    width: 100%;
    max-width: 400px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #1E3252;
    color: #fff;
    cursor: pointer;
    margin: 0 auto;
}

.login-button:hover {
    background-color:#FCAF17;
}
.additional-options {
    margin-top: 30px;
    margin-bottom: 10px;
    text-align: center;
}
.additional-options > * {
    margin: 0 5px; /* '|' 기호 좌우에 여백 추가 */
}

/* History.js ----------------------------------------*/
.history-container {
    display: flex;
    align-items: center;
    justify-content: center; 
    text-align: center;
}
.text-container {
    padding-right: 20px;
}

/* Faculty.js ----------------------------------------*/
.faculty-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.faculty-member {
    flex: 0 1 calc(50% - 20px);
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-sizing: border-box;
}

@media (max-width: 1000px) {
    .faculty-member {
        flex: 0 1 100%;
    }
}

.faculty-member h3 {
    text-align: center;
}

.faculty-member p {
    text-align: center;
    margin-bottom: 20px;
}

/* Curriculums.js---------------------------------------------*/
.button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    z-index: 1;
}
button {
    border: none;
    border-radius: 5px;
    background-color: #1E3252;
    padding: 10px 30px;
    margin: 0 20px;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
}
button:hover {
    font-weight: bolder;
    background: #FCAF17;
    color: #333;
}
button.selected {
    background: #FCAF17;
    color: #333;
    font-weight: bolder;
}

/* Career.js ----------------------------------------*/
.career-container {
    max-width: 400px;
    margin: 30px auto;
    margin-bottom: 10px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center; /* 추가: 자식 요소 중앙 정렬 */
    font-size: 18px;
}

/* 공지사항 및 게시판 기능 ----------------------------------------*/
.notice-board {
    max-width: 900px;
    background: #fff;
    margin: 30px auto;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.notice-item {
    margin: 0 20px 20px -23px;
    border-bottom: 1px solid #ccc;
}

.notice-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.notice-title {
    text-align: left;
    flex-grow: 1;
    font-weight: normal;
    color: #1E3252;
    text-decoration: none;
    margin-right: 0 20px;
}
.notice-title:hover {
    text-decoration: underline;
}

/* 글 내용, 작성자, 댓글 */
.notice-body {
    font-weight: normal;
    color: #333;
    font-size: 15px;
}

.author {
    font-weight: normal;
    color: #333;
    font-size: 15px;
}

.comment {
    margin-left: -20px;
    font-weight: normal;
    color: #333;
    font-size: 13px;
}

.comment-button {
    font-size: 12px;
    margin: 0 0 5px 5px;
    padding: 8px 8px;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
}

/* 삭제 버튼 */
.delete-button {
    font-size: 12px;
    margin: 0 0 5px 10px;
    padding: 5px 8px;
    background-color: #D71921;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
}
.delete-button:hover {
    font-size: 12px;
    background-color: #962626;
    color: #333;
} 

/* 추가 입력란 */
.add-notice-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}

.add-notice {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: 0px;
}

.add-notice input,
.add-notice textarea {
    font-family: 'Noto Sans KR', sans-serif;
    padding: 8px;
    margin-bottom: 7px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.nickname-password {
    display: flex;
    gap: 10px; /* 간격 조정 */
}

.add-button {
    padding: 10px 15px;
    margin-bottom: 7px;
    border: none;
    border-radius: 4px;
    background-color: #1E3252;
    color: white;
    font-size: 15px;
    cursor: pointer;
}

@media(max-width: 800px){
    .add-notice input,
    .add-notice textarea {
        font-size: 12px;
    }
    .add-notice input,
    .add-notice textarea {
        width: 100%;
    }
}

@media(max-width: 520px){
    .add-button {
        font-size: 13px;
    }
}

/* 관리자 모드 버튼 */
.admin-mode {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.admin-mode button {
    margin-right: 10px;
    padding: 5px 20px;
    border: none;
    border-radius: 5px;
    background-color: #1E3252;
    color: white;
    cursor: pointer;
    font-size: 16px;
}
.admin-mode button:hover {
    background-color: #FCAF17;
    color: #333;
}

/* 관리자 모드 비밀번호 입력창 */
.password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}

.password-input {
    padding: 5px;
    margin-right: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.submit-password-button {
    padding: 5px 8px;
    margin-left: 0px;
    border: none;
    border-radius: 5px;
    background-color: #1E3252;
    font-size: 12px;
    color: white;
    cursor: pointer;
}
.submit-password-button:hover {
    background-color: #FCAF17;
}

/* SiteMap.js--------------------------------------*/
.sitemap {
    margin: 50px 40px 0 0;
}

.sitemap p {
    font-size: 20px;
}

.sitemap-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 0px 40px 0 0;
    justify-content: center;
}

.sitemap-container li {
    display: inline-block;
    font-weight: normal;
}

/* 글자---------------------------------------------*/
.article {
    max-width: 1200px;
    margin: 50px auto;
    margin-bottom: 10px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.article li{
    font-size: 18px;
    padding: 10px 0 10px 0;
    color: #1E3252;
    font-weight: bolder;
    display: block;
    margin-left: 0;
}
.article ui{
    font-size: 18px;
    padding: 0px 0px 0px 7px;
    margin-top: 30px;
    margin-left: 0;
}
.article p{
    padding: 0 0 10px 10px;
    text-align: left;
    font-size: 15px;
    margin-left: 0;
}
.article p span{
    padding: 0 0 0px 10px;
    text-align: left;
    font-size: 18px;
    margin-left: 0;
    font-weight: bolder;
}


li {
    display: block;
    font-weight: bolder;
    color: #555;
    font-size: 18px;
    text-align: left;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 0px;
}
p {
    color: #555;
    font-size: 18px;
    text-align: center;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 0px;
}

a {
    font-weight: normal;
    color: inherit;
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}

img {
    display: block;
    max-width: 800px;
    margin: 50px auto;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@media(max-width: 1000px) {
    img {
        max-width: calc(100% - 0px);
    }
}
