.image-container {
  position: relative;
  padding: 230px 0;
  margin: 30px -50px;
  overflow: hidden;
  background-color: #000;
}

/* 흰색 선 */
.image-container::before,
.image-container::after {
  content: "";
  position: absolute;
  background-color: #fff;
  opacity: 0.5;
  z-index: 1;
}
.image-container::before {
  bottom: 25px;
  left: 0;
  right: 0;
  height: 1px;
}
.image-container::after {
  top: 0;
  bottom: 0;
  right: 25px;
  width: 1px;
}

/* 메인 배경이미지--------------------------------------------------------- */
.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; 
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.fade-in {
  opacity: 1;
}
.next-image {
  z-index: -1;
}

/* 메인 배경 버튼--------------------------------------------------------- */
.main-button-container {
  position: absolute;
  bottom: 20px; 
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.circle-button {
  position: relative; /* 부모 요소로부터 상대적 위치 지정 */
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  border: none;
  cursor: pointer;
  margin: 0 30px;
  opacity: 0.5;
  padding: 0;
}

.circle-button::after {
  content: '';
  position: absolute;
  top: -11.7px;
  left: -11.7px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: transparent; /* 투명 */
  border: 2px solid #fff;
  transition: opacity 0.5s ease; /* 호버 효과를 위한 트랜지션 */
  opacity: 0; /* 초기에는 투명 상태 */
}
.circle-button:hover::after {
  opacity: 1; /* 호버 시 외부 원이 나타남*/
  opacity: 0.5;
}

.circle-button:hover {
  background-color: #fff;
  opacity: 1;
}

/* 메인화면 텍스트--------------------------------------------------------- */
.text {
  position: relative;
  text-align: left;
  z-index: 1;
}

.text-container {
  display: flex;
  justify-content: center;  /* 텍스트 중앙 정렬 */
}

@media (min-width: 900px) {
  .text-container {
    justify-content: flex-end; /* 텍스트 오른쪽 정렬 */
    margin-right: 100px;
  }
}

.text h1 {
  font-weight: normal;
  color: #FCAF17;
  font-size: 2.67vw;
}
.text h2 {
  font-weight: normal;
  color: WHITE;
}
.text h3 {
  font-weight: bolder;
  color: WHITE;
}

.text h2, .text h3 {
  font-size: 4vw; 
  margin: 10px 0;
}

@media (min-width: 1000px) {
  .text h2, .text h3 {
    font-size: 40px;
  }

  .text h1 {
    font-size: 26.7px;
  }
}